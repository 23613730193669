import React from 'react';
import styled from 'styled-components';

import SEO from '../components/seo';
import CSSReset from '../misc/CSSReset';
import MainLayout from '../layouts/MainLayout';

const IndexPage = () => (
	<>
		<SEO title="Welcome" />
		<CSSReset />
		<MainLayout>
			<Wrapper>React component library built with React Hooks and Styled Components</Wrapper>
			<Buttons>
				<a href="/docs">Documentation</a>
				<a href="https://github.com/koldy/ui">Github</a>
			</Buttons>
		</MainLayout>
	</>
);

const Wrapper = styled.div`
	background: #ffffff;
	border-radius: 2px;
	padding: 1rem;
	font-size: 1rem;
	text-align: center;
`;

const Buttons = styled.nav`
	text-align: center;
	padding: 2rem;

	> a {
		display: inline-block;
		vertical-align: middle;
		padding: 0.66rem;
		min-width: 240px;
		margin: 6px;
		background-color: #5f4d93;
		color: #efefef;
		border-radius: 3rem;
		font-weight: bold;

		&:hover {
			background-color: #50388e;
		}
	}
`;

export default IndexPage;
